import { FileStatusEnum } from '@/ui-kit/enums/file-uploader/file-status.enum';
import axios, { type CancelTokenSource } from 'axios';
import type { IFile, TFileId } from '@/ui-kit/models/files.model';
import { reactive } from 'vue';
import { FileHelper } from '@/ui-kit/utils/fileHelper.util';
import { downloadFileByBlob } from '@/ui-kit/utils/fileSaver.util';

export class FileTemp {
  public progress = 0;
  public error = null;
  public type: string;
  public status: FileStatusEnum = FileStatusEnum.Default;
  public token: CancelTokenSource;
  public tempId: TFileId = FileHelper.getTempId();
  public localFile: File;

  constructor(
    public file?: File | IFile,
    public isUploaded = false,
  ) {
    this.file = file;
    this.refreshCancelToken();
  }

  public static getReactiveInstance(file?: File | IFile, isUploaded = false): FileTemp {
    return reactive(new FileTemp(file, isUploaded));
  }

  public downloadLocalFile(): void {
    if (this.localFile) {
      downloadFileByBlob(this.localFile, this.localFile?.name);
    }
  }

  public setError(error: string): void {
    this.error = error;
  }

  public onUploaded(file: IFile): void {
    this.file = file;
    this.isUploaded = true;
  }

  public setStatus(status: FileStatusEnum): void {
    this.status = status;
    if (!FileHelper.isErrorStatus(status)) {
      this.error = null;
    }
    this.refreshCancelToken();
  }

  public refreshCancelToken(): void {
    this.token = axios?.CancelToken?.source();
  }

  public cancelToken(): void {
    this.token?.cancel();
    this.refreshCancelToken();
  }

  public static isFileTempInstance(entity: unknown): boolean {
    return entity instanceof FileTemp;
  }
}

// пачка добавленных файлов на проверку
export interface IVirusCheckRequest {
  files: Array<FileTemp>;
  transitionalStatus: FileStatusEnum;
  notify: (files: Array<FileTemp>) => void;
}
