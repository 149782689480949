<script setup lang="ts">
import { someFieldIsInvalidUtil } from '@/common/utils/someFieldIsInvalid.util';
import AuthAlert from '@/views/auth/components/AuthAlert.vue';
import TextField from '@/ui-kit/components/TextField.vue';
import type { IDictionary } from '@/common/models/dictionary';
import type { ISupplierRegister } from '@/views/auth/pages/registration/models/supplierRegister.model';
import { computed, ref, watch } from 'vue';
import PasswordField from '@/ui-kit/components/PasswordField.vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import { useField, useForm } from 'vee-validate';
import { Validators } from '@/ui-kit/utils/validators.util';
import type { IUserRegister } from '@/views/auth/pages/registration/models/userRegister.model';
import { ValidatorsMessagesEnum } from '@/ui-kit/enums/validators.enum';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import BackButton from '@/common/components/BackButton.vue';
import NoAccount from '@/views/auth/pages/registration/components/AccountAlert.vue';
import PasswordRules from '@/views/auth/components/PasswordRules.vue';
import type { ISupplierModel } from '@/views/auth/pages/invitation/models/supplierUser.model';
import { usePhoneMask } from '@/common/hooks/usePhoneMask';
import YandexCaptcha from '@/common/YandexCaptcha.vue';
import Checkbox from '@/ui-kit/components/Checkbox.vue';
import InformationTip from '@/ui-kit/components/InformationTip.vue';
import ExceededLimitTimer from '@/views/auth/components/ExceededLimitTimer.vue';
import { useTenantStore } from '@/stores/tenant.store';
import { privacyFileNameMap } from '@/common/constants/privacyFileNameMap.const';
import { PolicyFileTypeEnum } from '@/common/enums/policyFileType.enum';
import Notificator from '@/ui-kit/services/notificator.service';
import { usePolicyApi } from '@/common/api/usePolicyApi'

const props = defineProps<{
  legalForms?: Array<IDictionary>;
  supplier?: ISupplierRegister;
  supplierInvitation?: ISupplierModel;
  user?: IUserRegister;
  isLoading: boolean;
  captchaReset?: number;
  hideNoAccountAlert?: boolean;
  disableAutocomplete?: boolean;
  unblockTimerMs: number;
  attemptsLeft: number;
  isUnblockTimerStart: boolean;
}>();

const emit = defineEmits<{
  (e: 'proceed', value: IUserRegister): void;
  (e: 'back'): void;
  (e: 'clearTimer'): void;
}>();

const supplierFullName = computed<string>(() => {
  if (props.supplier) {
    const found = props.legalForms.find((item) => item.id === props.supplier.legalFormId);
    const legalFrom = found?.shortName || found?.name;
    const name = props.supplier.fullName;
    return [legalFrom, name].join(' ');
  } else if (props.supplierInvitation) {
    return props.supplierInvitation.fullName;
  } else {
    return '';
  }
});
const captchaToken = ref<string>();
const checkInputDataText = 'Проверьте введенные данные';
const isAgreedWithTerms = ref<boolean>(false);
const isAgreedWithDataProcessing = ref<boolean>(false);
const isAgreedwithUserRules = ref<boolean>(false);

const {values, errors} = useForm<IUserRegister>({
  validationSchema: {
    fullName: [
      Validators.required(checkInputDataText),
      Validators.maxLength(50,'Значение должно быть не более 50 символов'),
      Validators.pattern(/\S+\s+\S+/g, checkInputDataText),
    ],
    mobilePhoneNumber: [
      Validators.required(),
      Validators.phoneNumberMask(ValidatorsMessagesEnum.PhoneNumber),
    ],
    password: [
      Validators.required(checkInputDataText),
      Validators.password('Пароль не соответствует требованиям безопасности'),
      Validators.maxLength(50, 'Значение должно быть не более 50 символов'),
    ],
    confirmPassword: [
      Validators.compare('password', 'Значение не совпадает с введенным ранее'),
    ],
  },
  initialValues: {
    fullName: props.user ? `${props.user.lastName} ${props.user.firstName}${props.user.patronymic ? ' ' + props.user.patronymic : ''}` : '',
    lastName: props.user?.lastName,
    firstName: props.user?.firstName,
    patronymic: props.user?.patronymic,
    mobilePhoneNumber: props.user?.mobilePhoneNumber,
    password: props.user?.password,
    confirmPassword: props.user?.password,
  },
});

const fields = {
  fullName: useField<string>('fullName'),
  firstName: useField<string>('firstName'),
  lastName: useField<string>('lastName'),
  patronymic: useField<string>('patronymic'),
  mobilePhoneNumber: useField<string>('mobilePhoneNumber'),
  password: useField<string>('password'),
  confirmPassword: useField<string>('confirmPassword'),
};

const { phoneMaskOptions, onFocus, onChange, onKeyDown } = usePhoneMask(fields.mobilePhoneNumber, true);


const tenantStore = useTenantStore();
const { downloadPolicyFile } = usePolicyApi();

function proceed(): void {
  if (someFieldIsInvalidUtil(errors.value)) {
    Object.values(fields).forEach((field) => field.setTouched(true));
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {fullName, confirmPassword, ...result} = values;
    emit('proceed', { ...result, mobilePhoneNumber: `+${values.mobilePhoneNumber}`, smartToken: captchaToken.value });
  }
}

function onCaptchaSuccess(token: string): void {
  captchaToken.value = token;
}

  async function onDownloadPrivacyPolicyFile(): Promise<void> {
    try {
      await downloadPolicyFile(tenantStore?.tenant.policyPersonalFileId, privacyFileNameMap.get(PolicyFileTypeEnum.PrivacyPolicy))
    } catch (error) {
      Notificator.showDetachedNotification('Не удалось загрузить файл')
    }
}

async function onDownloadPolicyAgreement(): Promise<void> {
  try {
    await downloadPolicyFile(tenantStore?.tenant.consentPersonalFileId, privacyFileNameMap.get(PolicyFileTypeEnum.PrivacyPolicyAgreement))
  } catch (error) {
    Notificator.showDetachedNotification('Не удалось загрузить файл')
  }
}

async function onOpenUserAgreement(): Promise<void> {
  try {
    await downloadPolicyFile(tenantStore?.tenant.usageAgreementFileId, privacyFileNameMap.get(PolicyFileTypeEnum.UserAgreement))
  } catch (error) {
    Notificator.showDetachedNotification('Не удалось загрузить файл')
  }
}

watch(() => fields.fullName.value.value, (value) => {
  if (value) {
    const parts = value.split(' ');
    if (parts[0]) {
      fields.lastName.value.value = parts[0];
    }
    if (parts[1]) {
      fields.firstName.value.value = parts[1];
    } else {
      fields.firstName.value.value = null;
      fields.patronymic.value.value = undefined;
      return;
    }

    if (parts[2]) {
      fields.patronymic.value.value = parts.slice(2, parts.length).join(' ');
    }
  } else {
    fields.lastName.value.value = null;
    fields.firstName.value.value = null;
    fields.patronymic.value.value = null;
  }
});
</script>

<template>
  <div class="d-flex flex-column align-items-center">
    <div class="d-flex flex-column mt40 mb40">
      <BackButton
        v-if="supplier"
        @click="emit('back')"
      >
        Назад
      </BackButton>
      <div class="d-flex flex-column align-items-center position-relative">
        <AuthAlert class="mt24">
          <div class="d-flex flex-column">
            <div class="mm-headline-3 mm-color-black mm-font-500 mb16">Введите данные пользователя</div>
            <div class="mm-body-regular-s mm-color-black mb24">
              По завершении регистрации вы станете пользователем контрагента {{ supplierFullName }}
            </div>
            <TextField
              v-model="fields.fullName.value.value"
              icon-path=""
              :clearable="false"
              :disable-autocomplete="disableAutocomplete"
              label="ФИО"
              :disabled="isLoading"
              :validation-field="fields.fullName"
            />
            <TextField
              v-model="fields.mobilePhoneNumber.value.value"
              label="Номер мобильного телефона"
              :clearable="false"
              :disable-autocomplete="disableAutocomplete"
              :mask-options="phoneMaskOptions"
              :validation-field="fields.mobilePhoneNumber"
              :disabled="isLoading"
              icon-path=""
              @focus-change="onFocus"
              @update:model-value="onChange"
              @keydown="onKeyDown"
            />
            <PasswordField
              v-model="fields.password.value.value"
              :validation-field="fields.password"
              :disabled="isLoading"
              :disable-autocomplete="disableAutocomplete"
              label="Пароль"
            />
            <PasswordField
              v-model="fields.confirmPassword.value.value"
              :validation-field="fields.confirmPassword"
              :disabled="isLoading"
              :disable-autocomplete="disableAutocomplete"
              label="Подтвердить пароль"
            />

            <YandexCaptcha
              :key="captchaReset"
              @success="onCaptchaSuccess"
            />

            <div class="confirm-rules">
              <Checkbox
                v-model="isAgreedWithTerms"
                :disabled="isLoading"
              >
                <p>Подтверждаю свое согласие с <span @click.stop.prevent="onDownloadPrivacyPolicyFile">политикой обработки персональных данных</span></p>
              </Checkbox>
              <Checkbox
                v-model="isAgreedWithDataProcessing"
                :disabled="isLoading"
              >
                <p>Подтверждаю свое <span @click.stop.prevent="onDownloadPolicyAgreement">согласие на обработку персональных данных</span></p>
              </Checkbox>
              <Checkbox
                v-model="isAgreedwithUserRules"
                :disabled="isLoading"
              >
                <p>Принимаю условия <span @click.stop.prevent="onOpenUserAgreement">соглашения об использовании информационной системы SRM</span></p>
              </Checkbox>
            </div>

            <ExceededLimitTimer
              :unblock-timer-ms="unblockTimerMs"
              :attempts-left="attemptsLeft"
              @clear="emit('clearTimer')"
            />

            <div class="position-relative mt40">
              <button
                class="btn btn-primary button56 w-100"
                :disabled="someFieldIsInvalidUtil(errors) || isLoading || !captchaToken || !isAgreedWithTerms || isUnblockTimerStart || !isAgreedWithDataProcessing || !isAgreedwithUserRules"
                @click="proceed"
              >
                <span v-if="!isLoading">Зарегистрироваться</span>
              </button>
              <SvgIcon
                v-if="isLoading"
                class="uploading-icon"
                :src="IconPathEnum.IndicatorsProgressSvg"
              />
            </div>
          </div>
        </AuthAlert>
        <NoAccount
          v-if="!hideNoAccountAlert"
          class="mt40"
          title="Уже есть аккаунт?"
          to="login"
          actionText="Пройдите авторизацию"
        />
        <InformationTip>
          На указанный мобильный <br>
          телефон поступит СМС с <br>
          проверочным кодом для <br>
          завершения регистрации
        </InformationTip>
        <PasswordRules />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

:deep(.mm-input) {
  padding-bottom: 12px;
}

.tip {
  position: absolute;
  top: 170px;
  right: -335px;
  width: 247px;
}

.password-rules {
  top: 294px;
  right: -335px;
}

.confirm-rules {
  margin-top: 12px;
  border-radius: 6px;
  background: $light-gray;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .checkbox {
    :deep(.checkbox__input > span) {
      background-color: $text-white;
    }

    :deep(.checkbox__input > input:checked + span) {
      background-color: $text-dark-green;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text-black;
    }

    span {
      color: $link;
    }
  }
}
</style>
