import { ref, watch } from 'vue';
import IMask from 'imask';
import type { FieldContext } from 'vee-validate';

const INITIAL_MASK = '+00000000000000';

export function usePhoneMask(phoneValue: FieldContext<string>, useBrackets?: boolean) {
  const isFieldFocused = ref(false);
  const hasPlusPrefix = ref(false);
  const phoneMaskOptions = ref<IMask.AnyMaskedOptions>({
    mask: INITIAL_MASK,
  });

  watch(
    () => phoneValue.value.value,
    (newValue) => {
      if (hasPlusPrefix.value && !newValue.startsWith('8')) {
        hasPlusPrefix.value = false;
      }
    },
  );

  function formatPhoneNumber(value: string) {
    let mask = '+';

    if (value) {
      const cleanedValue = value.replace(/\D/g, '');

      if (cleanedValue.length <= 15) {
        if (cleanedValue.length >= 14) {
          mask += `0000 ${useBrackets ? '(' : ''}000${useBrackets ? ')' : '' } 000-00-00`;
        } else if (cleanedValue.length >= 13) {
          mask += `000 ${useBrackets ? '(' : ''}000${useBrackets ? ')' : '' } 000-00-00`;
        } else if (cleanedValue.length >= 12) {
          mask += `00 ${useBrackets ? '(' : ''}000${useBrackets ? ')' : '' } 000-00-00`;
        } else if (cleanedValue.length >= 11) {
          if (phoneValue.value.value && phoneValue.value.value.startsWith('8')) {
            phoneValue.value.value = '7' + phoneValue.value.value.slice(1);
          }

          mask += `0 ${useBrackets ? '(' : ''}000${useBrackets ? ')' : '' } 000-00-00`;
        } else {
          mask += '00000000000000';
        }
      }
    }

    return mask;
  }


  function onFocus(value: boolean) {
    if (value) {
      isFieldFocused.value = true;
      phoneMaskOptions.value.mask = INITIAL_MASK;
    } else {
      isFieldFocused.value = false;
      phoneMaskOptions.value.mask = formatPhoneNumber(phoneValue.value.value);
    }
  }

  function onKeyDown(event: KeyboardEvent) {
    if (event.key === '+') {
      hasPlusPrefix.value = true;
    }
  }

  function onChange(value: string) {
    if (!isFieldFocused.value) return;

    const newValue = value;

    phoneValue.value.value = newValue;

    const cleanedValue = newValue.replace(/\D/g, '');

    if (cleanedValue.startsWith('7')) {
      phoneMaskOptions.value.mask = `+{7} ${useBrackets ? '(' : ''}000${useBrackets ? ')' : '' } 000-00-00`;
    } else if (!hasPlusPrefix.value && cleanedValue.startsWith('8')) {
      phoneMaskOptions.value.mask = `+{8} ${useBrackets ? '(' : ''}000${useBrackets ? ')' : '' } 000-00-00`;
    } else {
      phoneMaskOptions.value.mask = INITIAL_MASK;
    }
  }

  return {
    phoneMaskOptions,
    formatPhoneNumber,
    onFocus,
    onChange,
    onKeyDown,
  };
}
