<script setup lang="ts">
import AuthAlert from '@/views/auth/components/AuthAlert.vue';
import TextField from '@/ui-kit/components/TextField.vue';
import PasswordField from '@/ui-kit/components/PasswordField.vue';
import { useField, useForm } from 'vee-validate';
import { Validators } from '@/ui-kit/utils/validators.util';
import { ValidatorsMessagesEnum } from '@/ui-kit/enums/validators.enum';
import { someFieldIsInvalidUtil } from '@/common/utils/someFieldIsInvalid.util';
import { computed, onBeforeMount, ref } from 'vue';
import type { ILogin } from '@/common/models/api/auth/login.model';
import { useAuthToken } from '@/common/composables/useAuthToken';
import Notificator from '@/ui-kit/services/notificator.service';
import { useRoute, useRouter } from 'vue-router';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import NoAccount from '@/views/auth/pages/registration/components/AccountAlert.vue';
import { useSupplierApi } from '@/common/api/useSupplierApi';
import { useProfileStore } from '@/stores/profile.store';
import { useUserApi } from '@/views/srm/account-settings/api/useUserApi';
import { usePhoneMask } from '@/common/hooks/usePhoneMask';
import YandexCaptcha from '@/common/YandexCaptcha.vue';
import SMSInputView from '@/views/auth/components/SMSInputView.vue';
import { useAuthorizationApi } from '@/views/auth/api/useAuthorizationApi';
import { SMSOtpTypeEnum } from '@/views/auth/api/enums/smsOtpType.enum';
import ExceededLimitTimer from '@/views/auth/components/ExceededLimitTimer.vue';
import { ErrorTypeEnum } from '@/common/enums/error-type.enum';
import { useMigrationWarning } from '@/common/composables/useCookieWarning';

const { saveToken } = useAuthToken();
const { getCurrent } = useUserApi();
const { repeatSmsForLogin, checkLastOtp, getSmsForLogin, checkSmsOtp } = useAuthorizationApi();
const profileApi = useSupplierApi();
const router = useRouter();
const userStore = useProfileStore();
const route = useRoute();
const successRedirectUrl = ref<string>();
const captchaToken = ref<string>();
const isSmsView = ref<boolean>();
const tempLoginToken = ref<string>();
const captchaResetter = ref<number>(0);
const isUnblockTimerStart = ref<boolean>(false);
const unblockTimerMsValue = ref<number>(0);
const attemptsLeftMsValue = ref<number>(0);

const { values, errors } = useForm<ILogin>({
  validationSchema: {
    password: [
      Validators.required(),
      Validators.password(ValidatorsMessagesEnum.PasswordSafetyRequirements),
    ],
    mobilePhone: [
      Validators.required(),
      Validators.phoneNumberMask(ValidatorsMessagesEnum.PhoneNumber),
    ],
  },
});

const fields = {
  mobilePhone: useField<string>('mobilePhone'),
  password: useField<string>('password'),
};

const { phoneMaskOptions, onFocus, onChange, onKeyDown } = usePhoneMask(fields.mobilePhone, true);

const confirmDisabled = computed(() => someFieldIsInvalidUtil(errors.value) || isLoading.value || !captchaToken.value || isUnblockTimerStart.value);
const isLoading = ref<boolean>(false);

async function checkSmsInformation() {
  return await checkLastOtp(SMSOtpTypeEnum.Authorization, `+${values.mobilePhone}`);
}

function onCaptchaSuccess(token: string): void {
  captchaToken.value = token;
}

async function onLoginStart(smsOtp: string): Promise<void> {
  const token = await profileApi.login({
    ...values,
    mobilePhone: `+${values.mobilePhone}`,
    smsOtp,
    token: tempLoginToken.value,
  });
  saveToken(token);
  const current = await getCurrent();
  userStore.setCurrent(current);
  const profileInfo = await profileApi.getBaseProfile();
  userStore.setProfile(profileInfo);
  await router.push(successRedirectUrl.value || '/');
}

async function onCheckSms(smsOtp: string): Promise<void> {
  await checkSmsOtp(SMSOtpTypeEnum.Authorization, {
    phone: `+${values.mobilePhone}`,
    smsOtp,
  });
  await onLoginStart(smsOtp);
}

async function repeatSMS(): Promise<void> {
  await repeatSmsForLogin(`+${values.mobilePhone}`, tempLoginToken.value);
}

function backSmsInputView() {
  isSmsView.value = false;
  captchaToken.value = null;
}

async function onConfirm(): Promise<void> {
  try {
    if (!confirmDisabled.value) {
      isLoading.value = true;
      tempLoginToken.value = (await getSmsForLogin({
        ...values,
        mobilePhone: `+${values.mobilePhone}`,
        smartToken: captchaToken.value,
      }))?.token;
      isSmsView.value = true;
    } else {
      Object.values(fields).forEach((field) => field.setTouched(true));
    }
  } catch (e) {
    captchaResetter.value++;
    captchaToken.value = null;
    if (e.response?.data?.detail?.error === ErrorTypeEnum.InvalidGrant) {
      fields.password.setErrors('Если Вам не удаётся войти в систему, нажмите «Забыли пароль?» для его смены.' +
        '\nВаш старый пароль мог быть деактивирован вследствие обновления системы');
      fields.mobilePhone.setErrors(' ');
    } else if (e.response?.data?.errors?.find(error => error.code === ErrorTypeEnum.Forbidden)) {
      const { unblockTimerMs, attemptsLeft } = await checkLastOtp(SMSOtpTypeEnum.Authorization, `+${values.mobilePhone}`);
      if (attemptsLeft >= 0) {
        attemptsLeftMsValue.value = attemptsLeft;
      }
      if (unblockTimerMs) {
        isUnblockTimerStart.value = true;
        unblockTimerMsValue.value = unblockTimerMs;
      }
      await router.push('/auth/access-denied');
    } else if (e.response?.data?.errors?.find(error => error.code === ErrorTypeEnum.SmsLimitExceeded)) {
      isSmsView.value = true;
    } else {
      Notificator.showDetachedNotification('Произошла непредвиденная ошибка');
    }
  } finally {
    isLoading.value = false;
  }
}

onBeforeMount(() => {
  if (route.query?.redirect) {
    successRedirectUrl.value = route.query.redirect as string;
    router.replace({ query: null });
  } else {
    useMigrationWarning();
  }
});
</script>

<template>
  <SMSInputView
    v-if="isSmsView"
    :key="isSmsView"
    :phone="values.mobilePhone"
    :callback-fn="onCheckSms"
    alternative-text
    :get-sms-fn="repeatSMS"
    :get-sms-timeout-fn="checkSmsInformation"
    @back="backSmsInputView"
  />
  <div
    v-else
    class="d-flex flex-column align-items-center mt100"
  >
    <AuthAlert class="mb40">
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center justify-content-between mb32">
          <div class="mm-headline-3 mm-color-black mm-font-500">Войти в аккаунт</div>
          <router-link
            class="mm-body-medium-s mm-text-undecorated-link"
            to="reset-password"
          >
            Забыли пароль?
          </router-link>
        </div>
        <TextField
          v-model="fields.mobilePhone.value.value"
          :validation-field="fields.mobilePhone"
          label="Номер мобильного телефона"
          :mask-options="phoneMaskOptions"
          icon-disabled
          :clearable="false"
          @focus-change="onFocus"
          @update:model-value="onChange"
          @keydown="onKeyDown"
        />
        <PasswordField
          v-model="fields.password.value.value"
          :validation-field="fields.password"
          :disabled="isLoading"
          label="Пароль"
        />

        <YandexCaptcha
          :key="captchaResetter"
          @success="onCaptchaSuccess"
        />

        <ExceededLimitTimer
          :unblock-timer-ms="unblockTimerMsValue"
          :attempts-left="attemptsLeftMsValue"
          @clear="isUnblockTimerStart = false"
        />

        <div class="position-relative mt40">
          <button
            class="btn btn-primary button56 w-100"
            :disabled="confirmDisabled"
            @click="onConfirm"
          >
            <span v-if="!isLoading">Войти</span>
          </button>
          <SvgIcon
            v-if="isLoading"
            class="uploading-icon"
            :src="IconPathEnum.IndicatorsProgressSvg"
          />
        </div>
      </div>
    </AuthAlert>
    <NoAccount
      title="Еще нет аккаунта?"
      to="registration"
      actionText="Пройдите регистрацию"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

:deep(.btn:disabled) {
opacity: 1;
}
</style>
