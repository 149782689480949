import Notificator from '@/ui-kit/services/notificator.service';
import { onMounted, onUnmounted } from 'vue';
import CookieWarning from '@/common/components/CookieWarningComponent.vue'
import { CookieManager } from '@/ui-kit/services/cookie.service';
import { SEEN_COOKIE_WARNING_KEY } from '@/ui-kit/constants/cookies.const';
import ReleaseMigrationWarning from '@/common/components/ReleaseMigrationWarning.vue';

export function useCookieWarning() {
  function showCookieWarning() {
    const seenWarning = CookieManager.getCookie(SEEN_COOKIE_WARNING_KEY);
    if (!seenWarning) {
       Notificator.showDetachedNotification(CookieWarning, {
        timeout: false,
        toastClassName: 'cookie-warning-notification',
        containerClassName: 'auto-width',
        onClose: () => CookieManager.setCookie(SEEN_COOKIE_WARNING_KEY, 'true'),
      })
    }
  }

  onMounted(() => {
    window.addEventListener('load', showCookieWarning);
  });

  onUnmounted(() => {
    window.removeEventListener('load', showCookieWarning);
  });
}

// XXX TEMPORARY MESSAGE, REMOVE WHEN NO LONGER NEEDED!
let isMigrationWarningShown = false;
export function useMigrationWarning() {
  if (!isMigrationWarningShown) {
    isMigrationWarningShown = true;
    Notificator.showDetachedNotification(ReleaseMigrationWarning, {
      timeout: false,
      toastClassName: 'release-migration-notification',
      containerClassName: 'auto-width',
      onClose: () => isMigrationWarningShown = false,
    });
  }
}
