export enum IconPathEnum {
  /**
   * Icons
   */
  ActionUpdate = 'action/update',
  ActionNewCopy = 'action/new-copy',
  ActionUpdateAccent = 'action/update-accent',
  ActionAttachSvg = 'action/attach',
  ActionCartMiniSvg = 'action/cart-mini',
  ActionCartSvg = 'action/cart',
  ActionCatalogSvg = 'icons/action/catalogs',
  ActionCloseSvg = 'action/close',
  ActionComment24PxSvg = 'action/comment-24px',
  ActionCommentReview24PxSvg = 'action/comment-review-24px',
  ActionCommentReviewSvg = 'action/comment-review',
  ActionCommentSvg = 'action/comment',
  ActionCompanySvg = 'action/company',
  ActionCompareSvg = 'action/compare',
  ActionComment20Svg = 'action/comment-20px',
  ActionCommentMini24Svg = 'action/comment-mini-24px',
  ActionCopySvg = 'action/copy',
  ActionDateSvg = 'action/date',
  ActionDownloadSvg = 'action/download',
  ActionEyeSvg = 'action/eye',
  ActionDeliverySvg = 'action/delivery',
  ActionFavouritesSvg = 'action/favourites',
  ActionFileErrorSvg = 'action/file-error',
  ActionGalleryViewSvg = 'action/gallery-view',
  ActionGluingSvg = 'action/gluing',
  ActionIconMinusDarkSvg = 'action/icon-minus-dark',
  ActionIconMinusSvg = 'action/icon-minus',
  ActionIconPlusDarkSvg = 'action/icon-plus-dark',
  ActionIconPlusSvg = 'action/icon-plus',
  ActionInfoSvg = 'action/info',
  ActionLinkSvg = 'action/link',
  ActionLimitsSvg = 'action/limits',
  ActionListingViewMiniSvg = 'action/listing-view-mini',
  ActionListingViewSvg = 'action/listing-view',
  ActionMinusSvg = 'action/minus',
  ActionModerationSvg = 'action/moderation',
  ActionMoreDotsSvg = 'action/more-dots',
  ActionOrderSvg = 'action/order',
  ActionPauseSvg = 'action/pause',
  ActionPdfPerforatedSvg = 'action/pdf-perforated',
  ActionPdfSvg = 'action/pdf',
  ActionFileUploading = 'action/file-uploading',
  ActionFileChecking = 'action/file-checking',
  ActionFileChecked = 'action/file-checked',
  ActionFileCheckError = 'action/file-check-error',
  ActionPenEditSvg = 'action/pen-edit',
  ActionPlusSvg = 'action/plus',
  ActionPriceRangeSvg = 'action/price-range',
  ActionPrintSvg = 'action/print',
  ActionRangeSvg = 'action/range',
  ActionRepeatSvg = 'action/repeat',
  ActionReportSvg = 'action/reports',
  ActionReviewSvg = 'action/review',
  ActionSettingsSvg = 'action/settings',
  ActionSpinnerSvg = 'action/spinner',
  ActionTaskListSvg = 'action/task-list',
  ActionThumbsDownSvg = 'action/thumbs-down',
  ActionThumbsUpSvg = 'action/thumbs-up',
  ActionTrashSvg = 'action/trash',
  ActionTrashTransparentSvg = 'action/trash-transparent',
  ActionTransferSvg = 'action/transfer',
  ActionUploadProcessingSvg = 'action/upload-processing',
  ActionUploadSvg = 'action/upload',
  ActionZoomInSvg = 'action/zoom-in',
  ActionVideoWhiteSvg = 'action/video-icon-white',
  ActionVideoGradientSvg = 'action/video-icon-gradient',
  ActionNotificationSvg = 'action/notification',
  ActionQuestionSvg = 'action/question',
  IndicatorsBestPriceSvg = 'indicators/best-price',
  IndicatorsBulletDotSvg = 'indicators/bullet-dot',
  IndicatorsBulletNumberSvg = 'indicators/bullet-number',
  IndicatorsCartItemsSvg = 'indicators/cart-items',
  IndicatorsCheckmarkRoundErrorSvg = 'indicators/checkmark-round-error',
  IndicatorsCheckmarkRoundFileSvg = 'indicators/checkmark-round-file',
  IndicatorsCheckmarkRoundWatchSvg = 'indicators/checkmark-round-watch',
  IndicatorsCheckmarkRoundDarkSvg = 'indicators/checkmark-round-dark',
  IndicatorsCheckmarkRoundSvg = 'indicators/checkmark-round',
  IndicatorsCheckmarkRoundGreenSvg = 'indicators/checkmark-round-green',
  IndicatorsCheckmarkWarnSvg = 'indicators/checkmark-warn',
  IndicatorsCheckmarkWarnBigSvg = 'indicators/checkmark-warn-big',
  IndicatorsCheckmarkAttentionSvg = 'indicators/checkmark-attention',
  IndicatorsCheckmarkSimpleSvg = 'indicators/checkmark-simple',
  IndicatorsCheckmarkSimpleGreySvg = 'indicators/checkmark-simple-grey',
  IndicatorsCheckmarkSvg = 'indicators/checkmark',
  IndicatorsFastShippingSvg = 'indicators/fast-shipping',
  IndicatorsInfoDarkSvg = 'indicators/info-dark',
  IndicatorsInfoOutlineSvg = 'indicators/info-outline',
  IndicatorsProgressSvg = 'indicators/progress',
  IndicatorsProgressDarkSvg = 'indicators/progress-dark',
  IndicatorsProgressLightSvg = 'indicators/progress-light',
  IndicatorsStarSvg = 'indicators/star',
  IndicatorsStepCloseSvg = 'indicators/step-close',
  IndicatorsUploadingSvg = 'indicators/uploading',
  IndicatorsWarningSvg = 'indicators/warning',
  NavigationArrowDown20PxSvg = 'navigation/arrow-down-20px',
  NavigationArrowDownSmallSvg = 'navigation/arrow-down-small',
  NavigationArrowDownSvg = 'navigation/arrow-down',
  NavigationArrowLeft20PxSvg = 'navigation/arrow-left-20px',
  NavigationArrowLeftSmallSvg = 'navigation/arrow-left-small',
  NavigationArrowLeftSvg = 'navigation/arrow-left',
  NavigationArrowRight20PxSvg = 'navigation/arrow-right-20px',
  NavigationArrowRightSmallSvg = 'navigation/arrow-right-small',
  NavigationArrowRightSvg = 'navigation/arrow-right',
  NavigationArrowTop20PxSvg = 'navigation/arrow-top-20px',
  NavigationArrowUpSmallSvg = 'navigation/arrow-up-small',
  NavigationArrowUpSvg = 'navigation/arrow-up',
  NavigationClockSvg = 'navigation/clock',
  NavigationClose20PxSvg = 'navigation/close-20px',
  NavigationClose24PxSvg = 'navigation/close-24px',
  NavigationClose36PxSvg = 'navigation/close-36px',
  NavigationFilterNewSvg = 'navigation/filter-new',
  NavigationFilterSvg = 'navigation/filter',
  NavigationHelpSvg = 'navigation/help',
  NavigationHomeSvg = 'navigation/home',
  NavigationInfo = 'navigation/info',
  NavigationQualificationFill = 'navigation/qualification-fill',
  NavigationInfoFillSvg = 'navigation/info-fill',
  NavigationInfoFill16Svg = 'navigation/info-fill-16',
  NavigationInfoOrangeSvg = 'navigation/info-orange',
  NavigationInfoOutlineSvg = 'navigation/info-outline',
  NavigationInfoWhiteSvg = 'navigation/info-white',
  NavigationInfoYellowSvg = 'navigation/info-yellow',
  NavigationLkSvg = 'navigation/lk',
  NavigationLocationSvg = 'navigation/location',
  NavigationLoginSvg = 'navigation/login',
  NavigationLogin20pxSvg = 'navigation/login-20px',
  NavigationMainArrowSvg = 'navigation/main-arrow',
  NavigationMenuSvg = 'navigation/menu',
  NavigationSearchSvg = 'navigation/search',
  NavigationSmallArrowDownSvg = 'navigation/small-arrow-down',
  NavigationSmallArrowLeftSvg = 'navigation/small-arrow-left',
  NavigationSmallArrowRightSvg = 'navigation/small-arrow-right',
  NavigationSmallArrowUpSvg = 'navigation/small-arrow-up',
  NavigationSortingSvg = 'navigation/sorting',
  NavigationArrowBack = 'navigation/arrow-back',
  NavigationArrowLongDown20pxSvg = 'navigation/arrow-long-down-20-px',
  NavigationOpenNewTabSvg = 'navigation/open-new-tab',
  SocialTelegramLightSvg = 'social/telegram-light',
  SocialTelegramSvg = 'social/telegram',
  SocialYoutubeLightSvg = 'social/youtube-light',
  SocialYoutubeSvg = 'social/youtube',
  SystemHandSvg = 'system/hand',
  SystemProfileSvg = 'system/profile',
  TextFormattingAlignSvg = 'text-formatting/align',
  TextFormattingBackgroundColorSvg = 'text-formatting/background-color',
  TextFormattingBoldSvg = 'text-formatting/bold',
  TextFormattingBulletListSvg = 'text-formatting/bullet-list',
  TextFormattingColorSvg = 'text-formatting/color',
  TextFormattingHeadingSvg = 'text-formatting/heading',
  TextFormattingIndentRightSvg = 'text-formatting/indent-right',
  TextFormattingItalicsSvg = 'text-formatting/italics',
  TextFormattingOrderedListSvg = 'text-formatting/ordered-list',
  TextFormattingOutdentSvg = 'text-formatting/outdent',
  TextFormattingPolygonSvg = 'text-formatting/polygon',
  TextFormattingRedoSvg = 'text-formatting/redo',
  TextFormattingStrikeSvg = 'text-formatting/strike',
  TextFormattingUnderlineSvg = 'text-formatting/underline',
  TextFormattingUndoSvg = 'text-formatting/undo',
  LogoDescOffSvg = 'logo-desc-off',
  PlaySvg = 'action/play',
  PlayInvertedSvg = 'action/play-inverted',
  ChevronBackSvg = 'navigation/chevron-back',
  LockSvg = 'action/lock',
  UnLockSvg = 'action/unlock',
  FilesSvg = 'action/files',
  ActionDownloadTransparentSvg = 'action/download-transparent',
  NavigationMainArrowTransparent = 'navigation/main-arrow-transparent',
  IndicatorsProcessDarkGreen = 'indicators/process-dark-green',
  ActionFileCheckedGreen = 'action/file-checked-green',
  ActionFileCheckErrorRed = 'action/file-check-error-red',
  SmallArrow = 'action/small-arrow',
  SmallLoupe = 'action/small-loupe',

  /**
   * Images
   */
  ImagesLogoInvertedDescOn = 'images/logo-inverted-desc-on',
  ImagesLogoDescOn = 'images/logo-desc-on',
  ImagesLogoInvertedDescOff = 'logo-inverted-desc-off',
  ImagesLogoDescOff = 'images/logo-desc-off',
  ImagesLogoIcon = 'images/logo-icon',
  ImagesLogoIconInverted = 'images/logo-icon-inverted'
}
