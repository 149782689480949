<script setup lang="ts">
import TextField from '@/ui-kit/components/TextField.vue';
import { computed, inject, onMounted, ref, watch } from 'vue';
import type { ITimezone } from '@/views/srm/account-settings/models/timezone.model';
import { useTimezonesStore } from '@/stores/timezones.store';
import Select from '@/ui-kit/components/Select.vue';
import PasswordField from '@/ui-kit/components/PasswordField.vue';
import { useUserApi } from '@/views/srm/account-settings/api/useUserApi';
import { useField, useForm } from 'vee-validate';
import { Validators } from '@/ui-kit/utils/validators.util';
import { GeneralInfoFormEnum } from '@/views/srm/account-settings/models/generalInfoFormValidation.enum';
import type { IUserProfileData } from '@/views/srm/account-settings/models/userProfileData.model';
import type { IPatchUserProfileReqBody } from '@/views/srm/account-settings/models/userProfileUpdate.model';
import ModalManager from '@/ui-kit/services/modalManager.service';
import ResetPasswordModal from '@/views/srm/account-settings/components/ResetPasswordModal.vue';
import Notificator from '@/ui-kit/services/notificator.service';
import SuccessIcon from '@/ui-kit/components/icons/SuccessIcon.vue';
import IMask from 'imask';
import ClipLoader from '@/ui-kit/components/ClipLoader.vue';
import { usePhoneMask } from '@/common/hooks/usePhoneMask';
import { useProfileStore } from '@/stores/profile.store';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import { PopperPlacementEnum } from '@/ui-kit/enums/popperPlacement.enum';
import BaseTooltip from '@/ui-kit/components/BaseTooltip.vue';
import { emailPattern } from '@/common/constants/emailPattern';
import { ValidatorsMessagesEnum } from '@/ui-kit/enums/validators.enum';
import { useAuthorizationApi } from '@/views/auth/api/useAuthorizationApi';
import { ErrorTypeEnum } from '@/common/enums/error-type.enum';

const userProfileApi = useUserApi()
const userStore = useProfileStore();

const { repeatEmailConfirm } = useAuthorizationApi()
const userProfileData = ref<IUserProfileData>()

const modalManagerService = inject<ModalManager>(ModalManager.getServiceName());

const validationSchema = {
  [GeneralInfoFormEnum.Phone]: [],
  [GeneralInfoFormEnum.Position]: [
    Validators.maxLength(100),
  ],
  [GeneralInfoFormEnum.SubPhone]: [
    Validators.maxLength(10),
  ],
  [GeneralInfoFormEnum.Timezone]: [
      Validators.required(),
  ],
  [GeneralInfoFormEnum.Email]: [
    Validators.required(ValidatorsMessagesEnum.EmailConfirm),
    Validators.pattern(new RegExp(emailPattern), ValidatorsMessagesEnum.Email),
    Validators.maxLength(50, 'Значение должно быть не более 50 символов'),
  ],
};

const { setTouched, validate } = useForm({
  validationSchema,
});

const fields = {
  fullName: ref(''),
  email: useField<string>(GeneralInfoFormEnum.Email),
  role: ref(''),
  mobilePhoneNumber: useField<string>(GeneralInfoFormEnum.MobilePhone),
  position: useField<string>(GeneralInfoFormEnum.Position),
  phoneNumber: useField<string>(GeneralInfoFormEnum.Phone),
  extensionNumber: useField<string>(GeneralInfoFormEnum.SubPhone),
  userTimezone: useField<ITimezone>(GeneralInfoFormEnum.Timezone),
}

const passwordMask = '********'
const additionalPhoneMaskOptions: IMask.AnyMaskedOptions = {
  mask: '0000000000',
};

const isLoading = ref(false);

const timezonesStore = useTimezonesStore();

const timezones = computed<Array<ITimezone>>(() => timezonesStore.getTimezones);

const isEmailVerified = ref<boolean>(false);
const isNotUniqueEmail = ref<boolean>(false);

const isButtonDisabled = computed(() => {
  if ( userProfileData.value) {
    return userProfileData.value.position === fields.position.value.value &&
        userProfileData.value.timezone?.id === fields.userTimezone.value.value?.id &&
        userProfileData.value.phoneNumber?.replace('+', '') === fields.phoneNumber.value.value &&
        userProfileData.value.extensionNumber === fields.extensionNumber.value.value
        && userProfileData.value.email === fields.email.value.value
  }
  return true
})

const isValuesHasErrors = computed(() => {
  return fields.position.errorMessage.value || fields.phoneNumber.errorMessage.value || fields.extensionNumber.errorMessage.value || fields.email.errorMessage.value || fields.userTimezone.errorMessage.value
})

function preventCopy(event) {
  event.preventDefault()
}

async function onSave() {
  isLoading.value = true
  setTouched(true)

  if (!(await validate()).valid) {
    return;
  }

  const reqBody: IPatchUserProfileReqBody = {
    position: fields.position.value.value,
    phoneNumber: fields.phoneNumber.value.value ? '+' + fields.phoneNumber.value.value : null,
    extensionNumber: fields.extensionNumber.value.value,
    timezoneId: fields.userTimezone.value.value?.id || fields.userTimezone.value.value,
    email: fields.email.value.value,
  }

  try {
    const profileStore = useProfileStore();
    userProfileData.value = await userProfileApi.updateUserProfile(reqBody)
    profileStore.setTimezone(timezones.value?.find((tz) => tz.id === reqBody.timezoneId));
    initFormValues();
    Notificator.showDetachedNotification('Настройки успешно сохранены', {
      icon: SuccessIcon,
    });
  }
  catch(e) {
    console.error(e.message)
    if (e.response?.data?.errors?.find((item) => item.code === ErrorTypeEnum.ConflictingState)) {
      isNotUniqueEmail.value = true;
      return;
    }
    if (e.response.status === 400) {
      const errorFields = e.response.data.errors.map((e) => e.field)
      errorFields.forEach((error) => {
        fields[error].setErrors('Проверьте введенные данные')
      })
      return
    }
    if (e.response.status === 403) {
      Notificator.showDetachedNotification('Недостаточно прав для обновления настроек аккаунта');
      return
    }
    Notificator.showDetachedNotification('Произошла непредвиденная ошибка при сохранении данных');
  } finally {
    isLoading.value = false
  }

}

function openPasswordModal(): void {
  modalManagerService
      .openAsyncModal<typeof ResetPasswordModal, string>(ResetPasswordModal, {
        attrs: {
          title: 'Смена пароля',
        },
      })
      .then((res) => {
        console.warn(res);
      });
}

function initFormValues(): void {
  const phoneNumber = userProfileData.value.phoneNumber?.replace('+', '') || '';
  const mobilePhoneNumber = userProfileData.value.mobilePhoneNumber?.replace('+', '') || '';
  fields.fullName.value = [userProfileData.value?.lastName, userProfileData.value?.firstName, userProfileData.value?.patronymic].filter((el) => !!el).join(' ');
  fields.email.value.value = userProfileData.value.email || '';
  fields.role.value = userStore.current.roles[0].description || ''
  fields.position.value.value = userProfileData.value.position || ''
  fields.phoneNumber.value.value = phoneNumber;
  fields.extensionNumber.value.value = userProfileData.value.extensionNumber || ''
  fields.userTimezone.value.value = userProfileData.value.timezone ? {
    ...userProfileData.value.timezone,
    name: `GTM+${userProfileData.value.timezone?.offset} ${userProfileData.value.timezone?.name}`,
  } : null;
  phoneMaskOptions.value.mask = formatPhoneNumber(phoneNumber);
  mobilePhoneMaskOptions.value.mask = formatMobilePhoneNumber(mobilePhoneNumber);
  fields.mobilePhoneNumber.value.value = mobilePhoneNumber;
  validationSchema[GeneralInfoFormEnum.Phone] = userProfileData.value?.isContactUser ? [
    Validators.required(),
    Validators.maxLength(20),
    Validators.minLength(11),
  ] : [];
  validate();
}

async function loadingProfileInfo() {
  isLoading.value = true
  try {
    userProfileData.value = await userProfileApi.getUserProfile()
    initFormValues()
  } catch(e) {
    console.error(e.message)
    Notificator.showDetachedNotification('Произошла ошибка при загрузке профиля пользователя');
  } finally {
    isLoading.value = false
  }
}

async function sendEmailRepeatConfirm() {
  try {
    await repeatEmailConfirm(fields.email.value.value);
    Notificator.showDetachedNotification(`На почту ${fields.email.value.value} отправлена ссылка для подтверждения. Проверьте почту и пройдите по ссылке из письма.`);
  } catch (e) {
    console.error(e);
    if (e.response?.data?.errors?.find((item) => item.code === ErrorTypeEnum.ConflictingState)) {
      isNotUniqueEmail.value = true;
      return;
    }
    Notificator.showDetachedNotification('Произошла непредвиденная ошибка');
  }
}

const { phoneMaskOptions, formatPhoneNumber, onFocus, onChange, onKeyDown } = usePhoneMask(fields.phoneNumber);
const { phoneMaskOptions: mobilePhoneMaskOptions, formatPhoneNumber: formatMobilePhoneNumber } = usePhoneMask(fields.mobilePhoneNumber);

onMounted(async () => {
  await loadingProfileInfo()
})

watch(
  () => userProfileData.value?.isEmailVerified,
  (newValue) => {
    isEmailVerified.value = newValue;
  },
);
</script>

<template>
  <div>
    <div v-if="timezones.length && userProfileData">
      <div class="mm-profile-wrapper">
        <div>
          <h2 class="mm-profile-title mm-profile-title--account">Учетные данные</h2>
          <p class="mm-profile-text">Для редактирования данных профиля обратитесь к администратору</p>
          <form
            class="mm-profile-form"
            @submit.prevent
          >
            <div class="mm-account-data-wrapper">
              <TextField
                v-model="fields.fullName.value"
                label="ФИО"
                icon-path=""
                :clearable="false"
                disabled
              />
              <TextField
                v-model="fields.role.value"
                label="Роль"
                icon-path=""
                :clearable="false"
                disabled
              />
              <TextField
                v-model="fields.position.value.value"
                label="Должность"
                icon-path=""
                :max-length="100"
                :disabled="isLoading"
                :validation-field="fields.position"
                :clearable="false"
              />
              <Select
                v-if="timezones"
                v-model="fields.userTimezone.value.value"
                value-field="id"
                label-field="name"
                label="Часовой пояс"
                :disabled="isLoading"
                is-top-placement
                :validation-field="fields.userTimezone"
                :options="timezones"
                :searchable="false"
                :is-only-value-field="true"
              />
            </div>

            <h2 class="mm-profile-title mm-profile-title--contacts">Контактные данные</h2>

            <div class="mm-profile-contacts">
              <TextField
                v-model="fields.phoneNumber.value.value"
                class="mm-profile-contacts-input"
                label="Рабочий телефон"
                :mask-options="phoneMaskOptions"
                icon-path=""
                :disabled="isLoading"
                :validation-field="fields.phoneNumber"
                :clearable="false"
                @focus-change="onFocus"
                @update:model-value="onChange"
                @keydown="onKeyDown"
              />
              <TextField
                v-model="fields.extensionNumber.value.value"
                class="mm-profile-contacts-input"
                label="Добавочный номер"
                :mask-options="additionalPhoneMaskOptions"
                icon-path=""
                :disabled="isLoading"
                :validation-field="fields.extensionNumber"
                :clearable="false"
              />
            </div>
            <div class="mm-email-wrapper">
              <TextField
                v-model="fields.email.value.value"
                :validation-field="fields.email"
                label="Электронная почта"
                :error-message="isNotUniqueEmail ? 'Поле должно иметь уникальное значение' : null"
                icon-path=""
                :clearable="false"
                :max-length="50"
                @update:model-value="isNotUniqueEmail = false"
              />
              <span
                v-if="isEmailVerified && userProfileData.email"
                class="mm-email-icon"
              >
                <BaseTooltip
                  arrow
                  hover
                  :position="PopperPlacementEnum.Top"
                  offset-distance="9"
                >
                  <SvgIcon src="indicators/checkmark-round" />

                  <template #content>
                    <span>Почта подтверждена</span>
                  </template>
                </BaseTooltip>
              </span>
            </div>

            <div
              v-if="!isEmailVerified && userProfileData.email"
              class="mm-email-confirm"
            >
              <SvgIcon src="navigation/info-orange" />
              Для доставки уведомлений на почту необходимо <span @click="sendEmailRepeatConfirm">подтвердить почту</span>
            </div>

            <h2 class="mm-profile-title mm-profile-title--login">Данные для входа</h2>

            <TextField
              v-model="fields.mobilePhoneNumber.value.value"
              label="Телефон"
              :mask-options="mobilePhoneMaskOptions"
              icon-path=""
              :clearable="false"
              disabled
            />
            <div class="password-wrapper">
              <PasswordField
                v-model="passwordMask"
                readonly
                :is-show-icon="false"
                :disabled="isLoading"
                @copy="preventCopy"
              />
              <span
                class="mm-text-link"
                @click="openPasswordModal"
              >
                Сменить пароль
              </span>
            </div>
          </form>
        </div>
      </div>
      <div class="profile-footer d-flex justify-content-center align-items-center">
        <div class="profile-footer-content d-flex justify-content-end">
          <div class="position-relative">
            <button
              class="btn btn-primary d-flex justify-content-center"
              :disabled="isButtonDisabled || !!isValuesHasErrors || isLoading"
              @click="onSave"
            >
              <span>Сохранить</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <ClipLoader v-if="timezones.length && isLoading && userProfileData" />
  </div>
</template>

<style scoped lang="scss">
@import "@styles/base/common/variables";

.mm-profile {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 41px;
    margin-bottom: 70px;
    font-size: 14px;
    line-height: 20px;
  }

  &-title {
    font-size: 20px;
    line-height: 28px;
  }

  &-contacts {
    display: flex;
    justify-content: space-between;

    &-input {
      max-width: 374px;
      width: 100%;
    }
  }

  &-form {
    width: 756px;
  }

}
.profile-footer {
  width: 100%;
  height: 80px;
  background: #f2f5f4;
  border-top: 1px solid $dark-gray;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: sticky;
  bottom: -8px;
  left: -8px;
  z-index: 222;

  &-content {
    width: 100%;
    padding: 0 32px;

    button {
      height: 40px;
    }
  }
}

.password-wrapper {
  position: relative;
}

.mm-text-link {
  position: absolute;
  top: 17px;
  right: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  z-index: 10;
}

.mm-account-data-wrapper {
  margin-bottom: 48px;
}

.mm-profile-title {
  &--account {
    margin-bottom: 16px;
  }

  &--contacts {
    margin-bottom: 24px;
  }

  &--login {
    margin: 42px 0 24px 0;
  }
}

.mm-profile-text {
  margin-bottom: 24px;
  color: $text-dark-green;
}

.mm-email-confirm {
  display: flex;
  align-items: center;
  padding: 16px;
  background: $light-gray;
  border-radius: 7px;
  font-size: 14px;
  color: $text-dark-green;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: contents;
    cursor: pointer;
    color: $link;
  }

  svg {
    margin-right: 12px;
  }
}

.mm-email-wrapper {
  position: relative;
}

.mm-email-icon {
  position: absolute;
  top: 18px;
  right: 16px;
  z-index: 2;

  :deep(.mm-tooltip.mm-tooltip--base .popper) {
    max-width: 141px;
    width: 141px;
  }
}
</style>
